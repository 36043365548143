import { defaultTheme } from 'react-admin';

export const customTheme: any = {
  ...defaultTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#3f51b5',
    },
  },
  sidebar: {
    width: 160,
    closedWidth: 0,
  },
  components: {
    ...defaultTheme.components,
    RaShow: {
      styleOverrides: {
        root: {
          '& .RaShow-card': { paddingBottom: 20 },
        },
      },
    },
    RaRichTextInput: {
      styleOverrides: {
        root: {
          '& .ProseMirror': { minHeight: '300px' },
        },
      },
    },
    RaMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '2px',
          '& .RaMenuItem-nestedList': {
            paddingLeft: 0,
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          paddingLeft: '6px',
          paddingBottom: '20px',
        },
      },
    },
  },
};
