import {MenuItemNode, MultiLevelMenu} from '@react-admin/ra-navigation';
import {menuItemsList} from '../config/menuItemList';
import {IMenuItem} from '../models/menu-item.model';
import {IfCanAccess} from '@react-admin/ra-rbac';


interface IMenuItemProps {
    item: IMenuItem;
}

const MenuItemWithAccessCheck = ({item}: IMenuItemProps) => {
    return (
        <IfCanAccess action="list" resource={item.resource}>
            <MenuItemNode
                name={item.name}
                to={item.to}
                label={item.label}
                icon={item.icon}
            />
        </IfCanAccess>
    );
};

const MenuList = () => {
    return (
        <MultiLevelMenu
            initialOpen={true}
            sx={{
                marginTop: 3,
            }}
        >
            {menuItemsList.map((mi) =>
                mi.children ? (
                    <MultiLevelMenu.Item key={mi.label} name={mi.name} label={mi.label}>
                        {mi.children.map((c: any) => (
                            <MenuItemWithAccessCheck key={c.label} item={c}/>
                        ))}
                    </MultiLevelMenu.Item>
                ) : (
                    <MenuItemWithAccessCheck key={mi.label} item={mi}/>
                )
            )}
        </MultiLevelMenu>
    );
};

export default MenuList;