import { getPermissionsFromRoles } from '@react-admin/ra-rbac';
import { AuthProvider, localStorageStore } from 'react-admin';
import {getContactRoles, getWriterByEmailAndPassword } from '../services/authService';
const localStore = localStorageStore();

export const authProvider: AuthProvider = {
  login: async ({ email, password, allRoles }) => {
    try {
      const user = await getWriterByEmailAndPassword(email, password)

      if (!user) {
        return Promise.reject('User is not valid');
      }
      const userRoles = await getContactRoles(user.id);
      const permissions = getPermissionsFromRoles({
        roleDefinitions: allRoles,
        userRoles: userRoles,
      });
      localStorage.setItem('email', email);
      localStorage.setItem('FullName', user.FullName);
      localStorage.setItem('userId', user.id.toString());
      return Promise.resolve({
        id: user.id,
        permissions
      });
    } catch (error) {
      return Promise.reject('An error occurred');
    }
  },
  logout: () => {
    localStorage.removeItem('email');
    localStorage.removeItem('FullName');
    localStorage.removeItem('userId');
    return Promise.resolve();
  },
  checkAuth: () => {
    const email = localStorage.getItem('email') as string;
    try {
      if (!email) return Promise.reject({ message: 'No Email Found' });
    } catch (error) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const email = localStorage.getItem('email') as string;
      if (!email) return Promise.reject({ message: 'Email not found' });
      const fullName = localStorage.getItem('FullName') as string;
      const id = localStore.getItem('userId') as string;
      return Promise.resolve({
        id,
        fullName,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const permissions = localStore.getItem('userPermissions');
    if (!permissions) return Promise.reject('No permissions');
    return Promise.resolve(permissions);
  },
};
