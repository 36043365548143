import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
interface ITitleProps {
    title: string;
    propertyName?: string;
}

const useStyles = makeStyles({
    recordTitle: {
        marginRight: 10,
        color: '#3f51b5',
    },
    entityTitle: {
        color: '#00000099',
    }
});

const TitleWrapper = ({ title, propertyName }: ITitleProps) => {
    const classes = useStyles();
    const record = useRecordContext();
    if (!propertyName) return <span>{`${title}`}</span>;
    if (!record) return null;

    return (
        <>
            <span className={classes.recordTitle}>{` ${record[propertyName]}`}</span>
            <span className={classes.entityTitle}>{`(${title})`}</span>
        </>
    );
};

export default TitleWrapper;