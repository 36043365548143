import './App.css';
import React, { useState } from 'react';
import { Admin, CustomRoutes, Loading, Resource } from 'react-admin';
import { authProvider } from './authorization/authProvider';
import LoginForm from './components/login/LoginForm';
import useHasuraDataProvider from './data/useHasuraDataProvider';
import MyLayout from './layout/layout';
import { Route } from 'react-router-dom';
import PostApproved from './components/postApproved/postApproved';
import PostInProgress from './components/postInProgress/postInProgress';
import PurchaseOrder from './components/purchaseOrder/purchaseOrder';
import PostShow from './components/post/postShow';
import PostEdit from './components/post/postEdit';
import { addEventsForMutations } from '@react-admin/ra-audit-log';
import { customTheme } from './layout/theme';
import appQueryClient from "./data/appQueryClient";

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  useHasuraDataProvider(setDataProvider);
  if (!dataProvider) return <Loading />;
  const dataProviderWithLogs = addEventsForMutations(
    dataProvider,
    authProvider,
    { name: 'v1_Events', resources: ['v1_Posts'] }
  );
  return (
    <Admin
      theme={customTheme}
      queryClient={appQueryClient}
      authProvider={authProvider}
      dataProvider={dataProviderWithLogs}
      loginPage={LoginForm}
      layout={MyLayout}
      dashboard={PostInProgress}
    >
      <Resource name="v1_Posts" show={PostShow} edit={PostEdit} />
      <CustomRoutes>
        <Route path="/in-progress" element={<PostInProgress />} />
        <Route path="/approved" element={<PostApproved />} />
        <Route path="/payments" element={<PurchaseOrder />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
