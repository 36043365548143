import { Box } from '@mui/material';
import { useRecordContext, useResourceContext } from 'react-admin';
import EventsAndCommentsTimeline from '../common/EventsAndCommentsTimeline';

const RecordTimeLineWrapper = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <Box sx={{ p: '.5rem', minWidth: '25rem' }}>
      <EventsAndCommentsTimeline
        record={record && record}
        resource={resource && resource}
        eventResource="v1_Events"
      />
    </Box>
  );
};
export default RecordTimeLineWrapper;
