import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useInput} from 'react-admin';
import {ReferenceProps} from '../../models/input-field.models';


const TinyMce = (props: ReferenceProps) => {
    const {source, ...rest} = props;
    const {
        id, isRequired, formState, fieldState, field
    } = useInput({source, ...rest})
    return (
        <Editor ref={props.ref} apiKey={process.env.REACT_APP_TINY_MCE_API_KEY} init={{
            menubar: false,
            plugins: [
                "image",
                "link",
                "wordcount",
                "anchor",
                "code",
                "lists",
                "textcolor blocks",
              ],
            toolbar: [
              "blocks undo redo styleselect link image media table anchor bold italic underline strikethrough forecolor backcolor numlist bullist align outdent indent removeformat code",
            ],
            images_upload_url: `${process.env.REACT_APP_PROXY_BASE}/upload`,
            referrer_policy: 'unsafe-url',
            height: 1000

        }}
                value={field.value}
                onEditorChange={(newValue, editor) => {
                    field.onChange(newValue)
                }}
        />
    );
};

export default TinyMce;
