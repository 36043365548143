import {Edit} from 'react-admin';
import RecordTimeLineWrapper from './../common/recordTimelineWrapper';
import React from 'react';
import PostForm from './postForm';
import TitleWrapper from '../common/titleWrapper';

const PostEdit = () => {
    return (
        <Edit
            aside={<RecordTimeLineWrapper/>}
            title={<TitleWrapper title="Post" propertyName="id"/>}
            redirect="show"
        >
            <PostForm/>
        </Edit>
    );
};
export default PostEdit;
