import { Card, CardContent, Grid } from '@mui/material';
import { List, useStore } from 'react-admin';
import useGetUserId from '../../hooks/useGetUserId';
import PurchaseOrderFields from './purchaseOrderFields';

const PurchaseOrder = (props: any) => {
  const userId = useGetUserId();
  return (
    <Grid container display="flex" flexDirection="column" sx={{ mt: '20px' }}>
      <Grid item>
        <Card {...props}>
          <CardContent>
            <List
              title="Payments"
              resource="v1_new_POBase"
              filter={{ supplier_id: userId }}
              perPage={25}
              empty={false}
              sort={{ field: 'CreatedOn', order: 'DESC' }}
            >
              <PurchaseOrderFields />
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PurchaseOrder;
