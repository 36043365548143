import {Datagrid, DateField, FunctionField, NumberField, ReferenceField, TextField, WrapperField} from "react-admin";

const PostApprovedFields = () => (
    <Datagrid rowClick="show" bulkActionButtons={false}>
        <DateField source="new_DateApproved" label="Approved On" locales="en-GB"/>

        <TextField source="id" label="Post ID"/>

        <TextField source="new_Title" label="H1 Title"/>

        <ReferenceField
            label="Content Type"
            source="contenttype_id"
            reference="v1_ContentTypes"
            link="show"
        >
            <TextField source="new_name"/>
        </ReferenceField>

        <NumberField source="new_RequestedMinWords" label="Requested Words"/>

        <NumberField source="new_wordcount" label="Actual Words"/>

        <DateField source="CreatedOn" label="Ordered On" locales="en-GB"/>

        <DateField source="new_DateApproved" label="Approved On" locales="en-GB"/>

        <WrapperField label="Content Cost">
            <ReferenceField
                link={false}
                source="currency_id"
                reference="v1_TransactionCurrencyBase"
            >
                <TextField source="CurrencySymbol"/>
            </ReferenceField>
            <NumberField source="new_ContentCost"/>
        </WrapperField>

        <WrapperField label="Upload Price">
            <ReferenceField
                link={false}
                source="currency_id"
                reference="v1_TransactionCurrencyBase"
            >
                <TextField source="CurrencySymbol"/>
            </ReferenceField>
            <NumberField source="new_UploadPrice"/>
        </WrapperField>

        <WrapperField label="Writers Fee">
            <ReferenceField
                link={false}
                source="currency_id"
                reference="v1_TransactionCurrencyBase"
            >
                <TextField source="CurrencySymbol"/>
            </ReferenceField>
            <FunctionField render={(record: any) => {
                return record && record.new_TotalPrice - record.new_Supplierprice
            }}/>
        </WrapperField>
    </Datagrid>
);

export default PostApprovedFields;