import {Card, Grid} from '@mui/material';
import useGetUserId from '../../hooks/useGetUserId';
import ListWrapper from '../common/listWrapper';
import PostInProgressDeclinedFields from "./postInProgressDeclinedFields";
import PostInProgressOrderedFields from "./postInProgressOrderedFields";
import PostInProgressWaitingApprovalFields from './postInProgressWaitingApprovalFields';

const PostInProgress = (props: any) => {
	const userId = useGetUserId();
	return (
		<Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
			<Grid item>
				<Card {...props}>
					<ListWrapper
						cardTitle="Ordered"
						title="In Progress"
						resource="v1_Posts"
						filter={{writer_id: userId, new_ContentOrderStatus: 100000002}}
						sort={{ field: 'ordered_on', order: 'DESC' }}
					>
						<PostInProgressOrderedFields/>
					</ListWrapper>
					<ListWrapper
						cardTitle="Declined"
						title=" "
						resource="v1_Posts"
						filter={{writer_id: userId, new_ContentOrderStatus: 100000005}}
						sort={{ field: 'new_DeclineDate', order: 'DESC' }}
					>
						<PostInProgressDeclinedFields/>
					</ListWrapper>
					<ListWrapper
						cardTitle="Waiting Approval"
						title=" "
						resource="v1_Posts"
						filter={{writer_id: userId, new_ContentOrderStatus: 100000003}}
						sort={{ field: 'new_SenttoApproval', order: 'DESC' }}
					>
						<PostInProgressWaitingApprovalFields/>
					</ListWrapper>
				</Card>
			</Grid>
		</Grid>
	);
};

export default PostInProgress;
