import { Divider, Grid, Typography } from '@mui/material';
import {
  DateField,
  NumberField,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';
import { MarkdownField } from '@react-admin/ra-markdown';
import RecordTimeLineWrapper from '../common/recordTimelineWrapper';
import TitleWrapper from '../common/titleWrapper';
const PostShow = () => {

  return (
    <Show
      aside={<RecordTimeLineWrapper />}
      title={<TitleWrapper title="Post" propertyName="id" />}
    >
      <Grid container sx={{ pt: 2, pl: 2 }}>
        <Grid item xs={5}>
          <Typography>Basic Info</Typography>
          <SimpleShowLayout spacing={2} divider={<Divider />}>
            <TextField source="new_Title" label="H1 Title" />
            <TextField source="new_SeoTitle" label="SEO Title" />
            <TextField source="new_PostDesciption"   label="SEO Description" />
            <TextField label="Alias" source="new_alias" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={5} sx={{ mt: 3 }}>
          <SimpleShowLayout spacing={2} divider={<Divider />}>
            <ReferenceField
              label="Language"
              source="language_id"
              reference="v1_new_languagesBase"
              link="show"
            >
              <TextField source="new_name" />
            </ReferenceField>
            <ReferenceField
              source="website_id"
              link="show"
              label="Website"
              reference="v1_Website"
            >
              <TextField source="new_name" />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Typography>Content Details</Typography>
            <SimpleShowLayout spacing={2} divider={<Divider />}>
              <ReferenceField
                label="Content Type"
                source="contenttype_id"
                reference="v1_ContentTypes"
                link="show"
              >
                <TextField source="new_name" />
              </ReferenceField>
              <NumberField
                source="new_RequestedMinWords"
                label="Requested Words"
              />
              <NumberField source="new_wordcount" label="Actual Words" />
              <DateField
                source="new_Contentduedate"
                label="Due Date"
                locales="en-GB"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6}>
            <Typography>Writing Instructions</Typography>
            <SimpleShowLayout spacing={2} divider={<Divider />}>
              <RichTextField
                source="requestDetails"
                label="Request Details (keywords)"
              />
              <RichTextField
                label="Writing Instructions"
                source="new_WritingInstructions"
              />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Typography>Link Details</Typography>
            <SimpleShowLayout spacing={2} divider={<Divider />}>
              <UrlField source="new_TargetURL1" label="Url Details" />
              <TextField source="new_Anchortext1" label="Anchor Text" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <Typography>Post Body</Typography>
            <SimpleShowLayout>
              <MarkdownField source="new_TextHTMLbody" label={false} />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Grid>
    </Show>
  );
};
export default PostShow;
