import {Identifier, SaveButton, useNotify, useRedirect, useRefresh, useUpdate,} from 'react-admin';
import {useFormContext} from 'react-hook-form';

const UpdateButton = (props: UpdateButtonProps) => {
    const {resource, label, data, id, name, route} = props;
    const [update] = useUpdate();
    const {getValues} = useFormContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const handleUpdate = (e: any) => {
        e.preventDefault();
        const formData = {...getValues(), ...data};
        update(
            resource,
            {id, data: formData},
            {
                onSuccess: () => {
                    notify(`${name} updated successfully`, {type: 'success'});
                    refresh();
                    redirect(`${route}`);
                },
                onError: () => {
                    notify(`An error occurred updating ${name}`, {type: 'error'});
                },
            }
        );
    };
    return (
        <SaveButton
            label={label}
            alwaysEnable={true}
            onClick={(e) => handleUpdate(e)}
            type="button"
            variant="contained"
            sx={{color: '#fafafa', backgroundColor: '#2e7d32', ml: 4}}
        />
    );
};
export default UpdateButton;
type UpdateButtonProps = {
    resource: string;
    label: string;
    data: any;
    id: Identifier;
    name: string;
    route: string;
};
