import {blobServiceClient} from '../config/azureBlobServiceClient';
import {azureBlobImageContainer} from '../environment-variables';

const containerClient = blobServiceClient.getContainerClient(
    azureBlobImageContainer
);
export const uploadFileToAzure = async (file: File, fileName: string) => {
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    const options = {blobHTTPHeaders: {blobContentType: file.type}};
    return await blockBlobClient.uploadData(file, options);
};

export const uploadBlobToAzure = async (blob: Blob, fileName: string) => {
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    return await blockBlobClient.upload(blob, blob.size);
};
