import {Grid, TextField, Typography} from '@mui/material';
import {useRef} from 'react';
import {
	DateInput,
	maxLength,
	ReferenceInput,
	SaveButton,
	SaveContextProvider,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useInput,
	useRecordContext,
	useSaveContext,
	AutocompleteInput, useGetOne
} from 'react-admin';
import {uploadFileToAzure} from '../../services/azureImageUploadService';
import {BlockBlobUploadResponse} from '@azure/storage-blob';
import {useFormContext} from 'react-hook-form';
import UpdateButton from '../common/updateButton';
import useWordCountCalculation from '../common/useWordCountCalculation';
import TinyMce from "../common/TinyMce";

const validateSEOTitle = [maxLength(66)];
const validateSEODescription = [maxLength(165)];
const WordCountInput = (props: any) => {
    const {field} = useInput({
        source: 'new_wordcount',
    });
    const {wordCount} = useWordCountCalculation(props, field?.value);
    return (
        <TextField {...field} label={'Actual Words'} value={wordCount} disabled/>
    );
};

const uploadImage = async (image: File | Blob) => {
    if (image instanceof File) {
        const fileType = image.name.slice(image.name.length - 3);
        const name = `${
            image.name
        }_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}_${new Date().getHours()}_${new Date().getMinutes()}.${fileType}`;
        let url: string;
        try {
            const response = (await uploadFileToAzure(
                image,
                name
            )) as BlockBlobUploadResponse;
            if (response.errorCode) {
                return response.errorCode;
            }
            url = response._response.request.url.split('?')[0];
            return url;
        } catch (error) {
            return 'An error occurred uploading image';
        }
    } else return 'Please try again.';
};

const PostForm = (props: any) => {
    const totalRef = useRef<number>(0);
    const {save: saveFromContext} = useSaveContext();
    const save = (data: any) => {
        const record = {...data, new_wordcount: totalRef.current};
        saveFromContext && saveFromContext(record);
    };
	const post = useRecordContext();
	const {data: campaign} = useGetOne('v1_Campaign', {id: post?.campaign_id});
    const PostEditToolbar = (props: any) => {
        const record = useRecordContext();
        const tinyRef = useRef(null);
        const {watch} = useFormContext();
        const {totalRef} = props;
        const isDeclined = record.new_ContentOrderStatus === 100000005;
        const isOrdered = record.new_ContentOrderStatus === 100000002;
	    const initialValue = 0;
	    const {wordCount} = useWordCountCalculation({...props, ...{ref: tinyRef}}, initialValue);
        return (
            <Toolbar {...props}>
                <SaveButton label="Save"/>
                {isDeclined || isOrdered ? (
                    <UpdateButton
                        id={record?.id}
                        label="Submit"
                        data={{
                            new_ContentOrderStatus: 100000003,
                            new_SenttoApproval: new Date(),
                            new_wordcount: wordCount,
                        }}
                        resource="v1_Posts"
                        name="Post"
                        route="/in-progress"
                    />
                ) : null}
            </Toolbar>
        );
    };
    return (
        <SaveContextProvider value={{save}}>
            <SimpleForm toolbar={<PostEditToolbar totalRef={totalRef}/>}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>Basic Details</Typography>
                    </Grid>
                    <Grid item xs={5} container flexDirection="column">
                        <TextInput source="new_Title" label="H1 Title"/>
                        <TextInput source="new_SeoTitle" label="SEO Title" validate={validateSEOTitle}/>
                        <TextInput source="new_PostDesciption"   label="SEO Description"  validate={validateSEODescription}/>
                        <TextInput label="Alias" source="new_alias" disabled={true}/>
                    </Grid>
                    <Grid item xs={5} container flexDirection="column">
	                    <ReferenceInput
		                    source="project_id"
		                    reference="v1_Project"
		                    filter={{
			                    statecode: 0,
		                    }}
		                    sort={{
			                    field: 'new_name',
			                    order: 'ASC',
		                    }}
	                    >
		                    <AutocompleteInput
			                    filterToQuery={(
				                    searchText
			                    ) => ({
				                    new_name:
				                    searchText,
			                    })}
			                    defaultValue={
				                    campaign?.project_id
			                    }
			                    optionText="new_name"
			                    label="Project"
			                    disabled={true}
		                    />
	                    </ReferenceInput>
                        <ReferenceInput
                            label="Language"
                            source="language_id"
                            reference="v1_new_languagesBase"
                        >
                            <SelectInput optionText="new_name" disabled={true}/>
                        </ReferenceInput>
                        <ReferenceInput
                            source="website_id"
                            label="Website"
                            reference="v1_Website"
                        >
                            <SelectInput optionText="new_name" disabled={true}/>
                        </ReferenceInput>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={5} container flexDirection="column">
                        <Typography>Content Details</Typography>
                        <ReferenceInput
                            label="Content Type"
                            source="contenttype_id"
                            reference="v1_ContentTypes"
                        >
                            <SelectInput
                                optionText="new_name"
                                label="Content Type"
                                disabled={true}
                            />
                        </ReferenceInput>
                        <TextInput
                            source="new_RequestedMinWords"
                            label="Requested Words"
                            disabled={true}
                        />
                        <WordCountInput totalRef={totalRef}/>
                        <DateInput
                            label="Due Date"
                            source="new_Contentduedate"
                            disabled={true}
                        />
                        <Typography>Link Details</Typography>
                        <TextInput source="new_TargetURL1" label="Url Details"/>
                        <TextInput source="new_Anchortext1" label="Anchor Text"/>
                    </Grid>
                    <Grid item xs={5} container flexDirection="column">
                        <Typography>Writing Instructions</Typography>
                        <TextInput
                            source="requestDetails"
                            label="Request Details (keywords)"
                            multiline
                            rows="4.6"
                        />
                        <TextInput
                            source="new_WritingInstructions"
                            label="Writing Instructions"
                            multiline
                            rows="12"
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={10} container flexDirection="column">
                        <Typography>Post Body</Typography>
                        <TinyMce source="new_TextHTMLbody"></TinyMce>
                    </Grid>
                </Grid>
            </SimpleForm>
        </SaveContextProvider>
    );
};
export default PostForm;
