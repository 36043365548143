import {CurrencyExchange, ModelTraining, PublishedWithChanges} from '@mui/icons-material';
import { IMenuItem } from '../models/menu-item.model';

export const menuItemsList: IMenuItem[] = [
    {
        name: 'Content Writing',
        resource: '',
        to: '/',
        label: 'Content Writing',

        children: [
            {
                name: '',
                resource: 'v1_Posts',
                to: '/in-progress',
                label: 'In Progress',
                icon: <ModelTraining color="secondary" fontSize="small"/>,
            },
            {
                name: '',
                resource: 'v1_Posts',
                to: '/approved',
                label: 'Approved',
                icon: <PublishedWithChanges color="secondary" fontSize="small"/>,
            },
            {
                name: '',
                resource: 'v1_new_POBase',
                to: '/payments',
                label: 'Payments',
                icon: <CurrencyExchange color="secondary" fontSize="small"/>,
            },
        ],
    },
]