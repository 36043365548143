import {useEffect, useState} from "react";
import {useFormContext} from 'react-hook-form';
import {EditorManager} from "tinymce";

const useWordCountCalculation = (props: any, initialValue: number) => {
	const {watch} = useFormContext();
	const {totalRef} = props;
	const [wordCount, setWordCount] = useState(initialValue);
	const value = watch('new_TextHTMLbody');
	useEffect(() => {
		const wordcount = ((window as any)?.tinymce as EditorManager)?.activeEditor?.plugins?.wordcount;
		setWordCount(wordcount?.body?.getWordCount());
		totalRef.current = wordcount?.body?.getWordCount();
	}, [totalRef, value]);
	return {
		wordCount
	}
}

export default useWordCountCalculation;
