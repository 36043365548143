import {Datagrid, DateField, ReferenceField, TextField} from "react-admin";

const PostInProgressOrderedFields = () => (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" label="Post ID"/>

        <ReferenceField
            label="Project"
            source="campaign_id"
            reference="v1_Campaign"
            link={false}
        >
            <ReferenceField
                reference="v1_Project"
                source="project_id"
                link={false}
            >
                <TextField source="new_name"/>
            </ReferenceField>
        </ReferenceField>

        <TextField source="new_name" label="URL Details"/>

        <DateField source="ordered_on" label="Ordered On" locales="en-GB"/>

        <ReferenceField
            label="Ordered By"
            source="requestedby_id"
            reference="v1_SystemUserBase"
            link="show"
        >
            <TextField source="name"/>
        </ReferenceField>

        <TextField source="new_Title" label="H1 Title"/>

        <DateField source="new_Contentduedate" label="Due Date" locales="en-GB"/>
    </Datagrid>
);

export default PostInProgressOrderedFields;