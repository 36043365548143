import { TextField, Button, Stack, Box, Typography } from '@mui/material';
import {
  useRecordEvents,
  UseRecordEventsOptions,
} from '@react-admin/ra-audit-log';
import {
  Timeline,
  TimelineGroup,
  TimelineProps,
} from '@react-admin/ra-audit-log/esm/src/Timeline';
import { useState } from 'react';
import { useRefresh } from 'react-admin';
import { useAddComment } from '../../hooks/useAddComment';
import { EventsAndCommentsTimelineItem } from './EventsAndCommentsTimelineItem';

type EventsAndCommentsTimelineProps = UseRecordEventsOptions &
  Omit<TimelineProps, 'records' | 'isLoading'>;
const EventsAndCommentsTimeline = (props: EventsAndCommentsTimelineProps) => {
  const { data, isLoading } = useRecordEvents(props);
  const {
    page,
    perPage,
    sort,
    order,
    eventResource,
    record,
    resource,
    skeleton,
    ...rest
  } = props;
  const refresh = useRefresh();
  const [commentBody, setCommentBody] = useState('');
  const addComment = useAddComment({
    record,
    resource,
    mutationOptions: {
      onSuccess: () => {
        setCommentBody('');
        refresh();
      },
    },
  });
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    addComment({ body: commentBody });
    return false;
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextField
            value={commentBody}
            onChange={(event) => setCommentBody(event.target.value)}
            multiline
            rows={3}
          />
          <Button type="submit" variant="contained" disabled={!commentBody}>
            Submit Comment
          </Button>
        </Stack>
      </form>
      {!isLoading && !data?.length ? (
        <Typography>There are no comments yet</Typography>
      ) : (
        <Timeline
          isLoading={isLoading}
          records={data}
          skeleton={skeleton}
          {...rest}
        >
          <TimelineGroup>
            <EventsAndCommentsTimelineItem />
          </TimelineGroup>
        </Timeline>
      )}
    </Box>
  );
};
export default EventsAndCommentsTimeline;
