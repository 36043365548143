import {Datagrid, DateField, FunctionField, NumberField, ReferenceField, TextField, WrapperField} from "react-admin";

const PurchaseOrderFields = () => (
    <Datagrid bulkActionButtons={false}>
        <DateField source="CreatedOn" label="Created On" locales="en-GB"/>
        <ReferenceField
            label="Writer"
            source="supplier_id"
            reference="v1_ContactBase"
        >
            <TextField source="FullName"/>
        </ReferenceField>
        <ReferenceField
            label="Status"
            link={false}
            source="statuscode"
            reference="v1_PurchaseOrderStatus"
        >
            <TextField source="value"/>
        </ReferenceField>
        <TextField source="new_PaymentAddress" label="Payment Address"/>
        <ReferenceField
            source="paymentmethod_id"
            label="Payment Method"
            link={false}
            reference="v1_new_paymentmethodBase"
        >
            <TextField source="new_name"/>
        </ReferenceField>
        <DateField
            source="new_DueDate"
            label="Due Date"
            locales="en-GB"
        />
        <WrapperField label="Amount">
            <ReferenceField
                source="currency_id"
                link={false}
                reference="v1_TransactionCurrencyBase"
            >
                <TextField source="CurrencySymbol"/>
            </ReferenceField>
            <NumberField source="new_Sum"/>
        </WrapperField>
        <NumberField source="new_BonusIfany" label="Bonus"/>
        <WrapperField label="Total">
            <ReferenceField
                source="currency_id"
                link={false}
                reference="v1_TransactionCurrencyBase"
            >
                <TextField source="CurrencySymbol"/>
            </ReferenceField>
            <FunctionField
                render={(record: any) =>
                    `${record.new_BonusIfany + record.new_Sum}`
                }
            />
        </WrapperField>
    </Datagrid>
);
export default PurchaseOrderFields;