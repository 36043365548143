import {QueryClient} from 'react-query';

export const appQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10 * 1000 * 60 * 60,
            cacheTime: 20 * 1000 * 60 * 60,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchInterval: false,
            refetchIntervalInBackground: false
        },
    },
});

export default appQueryClient;
