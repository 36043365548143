import * as React from 'react';
import {AppBar, ToggleThemeButton, useSidebarState, useTheme,} from 'react-admin';
import Toolbar from '@mui/material/Toolbar';
import {Box, Tooltip, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../layout/images/adzz-logo.png';
import {customTheme} from './theme';

const useStyles = makeStyles({
    logo: {
        maxWidth: '150px',
        marginLeft: -35,
    },
    pointer: {
        cursor: 'pointer',
    },
});

const darkTheme = {
    ...customTheme,
    palette: {mode: 'dark' as any},
};

const MyAppBar = (props: any) => {
    const [open, setOpen] = useSidebarState();
    const [theme] = useTheme();
    const classes = useStyles();
    return (
        <AppBar
            {...props}
            sx={
                theme.palette?.mode === 'light'
                    ? {
                        color: 'black',
                        backgroundColor: 'white',
                    }
                    : {
                        color: 'white',
                        backgroundColor: 'black',
                    }
            }
        >
            <Toolbar>
                <Tooltip title={open ? 'Close menu' : 'Open menu'}>
                    <div onClick={() => setOpen(!open)} className={classes.pointer}>
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </div>
                </Tooltip>
            </Toolbar>
            <Box flex="1">
                <Typography variant="h6" id="react-admin-title"></Typography>
            </Box>
            <ToggleThemeButton lightTheme={customTheme} darkTheme={darkTheme}/>
        </AppBar>
    );
};

export default MyAppBar;