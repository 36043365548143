import {hasuraHeaders, http} from './httpService';
import config from '../config.json';
import {IRole} from '../models/role.model';

const {hasuraBaseUrl, hasuraRestApi} = config;

export const getWriterByEmailAndPassword = async (
    email: string,
    password: string
): Promise<any> => {
    console.log(process.env.REACT_APP_HASURA_PROXY)
    const {data: userRolesData} = await http.post(
        `${process.env.REACT_APP_HASURA_PROXY}/writer-login`,
        {email, password},
        {
            headers: hasuraHeaders,
        }
    );
    return userRolesData['v1_ContactBase'][0];
};

export const getContactRoles = async (id: number): Promise<string[]> => {
    const {data: contactRolesData} = await http.get(
        `${process.env.REACT_APP_HASURA_PROXY}/role/${id}`,
        {
            headers: hasuraHeaders,
        }
    );
    return contactRolesData['v1_Contacts_Roles'].map((u: IRole) => u.Roles.name);
};
