import MenuList from "./menu";
import {Layout} from 'react-admin';
import {AppLocationContext} from '@react-admin/ra-navigation';
import MyAppBar from "./myAppBar";


const MyLayout = (props: any) => (
    <AppLocationContext>
        <Layout
            {...props}
            menu={MenuList}
            appBar={MyAppBar}
        />
    </AppLocationContext>
);
export default MyLayout;