import { Card, CardContent, Grid } from '@mui/material';
import { List, useStore } from 'react-admin';
import PostApprovedFields from './postApprovedFields';
import PostActions from './postActions';
import useGetUserId from './../../hooks/useGetUserId';

const PostApproved = (props: any) => {
  const userId = useGetUserId();
  return (
    <Grid container display="flex" flexDirection="column" sx={{ mt: '20px' }}>
      <Grid item>
        <Card {...props}>
          <CardContent>
            <List
              title="Approved"
              resource="v1_Posts"
              filter={{
                writer_id: userId,
                new_ContentOrderStatus: 100000004,
                statecode: 0,
              }}
              actions={<PostActions />}
              perPage={25}
              empty={false}
              sort={{ field: 'new_DateApproved', order: 'DESC' }}
            >
              <PostApprovedFields />
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PostApproved;
