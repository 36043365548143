import {DateInput, ExportButton, Filter, TopToolbar} from "react-admin";

const PostActions = () => {
    return (
        <>
            <Filter>
                <DateInput
                    alwaysOn
                    label="From"
                    source="new_DateApproved@_gte"
                />
                <DateInput
                    alwaysOn
                    label="To"
                    source="new_DateApproved@_lte"
                />
            </Filter>
            <TopToolbar>
                <ExportButton/>
            </TopToolbar>
        </>
    );
};
export default PostActions;
