import axios from 'axios';
import { apiKey } from '../environment-variables';
export const hasuraHeaders = {
  'content-type': 'application/json',
};
export const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
