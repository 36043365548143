import React, {useRef, useState} from 'react';
import {
    email,
    Form,
    Loading,
    Login,
    PasswordInput,
    SaveButton,
    TextInput,
    useGetList,
    useLogin,
    useNotify,
    useStore,
} from 'react-admin';
import LoginIcon from '@mui/icons-material/Login';
import {Box, Grid} from '@mui/material';
import logo from '../../layout/images/adzz-logo.png';
import {IRolesConfig} from '../../models/role.model';

const LoginForm = () => {
    const [emailInput, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const {data: allRoles, isLoading: rolesLoading} =
        useGetList('v1_Configurations');
    const [userId, setUserId] = useStore('userId', 0);
    const [userEmail, setUserEmail] = useStore('userEmail', '');
    const [userPermissions, setUserPermissions] = useStore('userPermissions', {});
    const rolesRef = useRef<any>([]);


    const handleSubmit = async (e: any) => {
        rolesRef.current = (allRoles as IRolesConfig[])
            ?.filter((r) => r.name === 'writers')
            .map((r) => r.config);
        try {
            const user = await login({
                email: emailInput,
                password,
                allRoles: rolesRef.current[0],
            });
            setUserId(user.id);
            setUserPermissions(user.permissions);
            setUserEmail(user.email);
        } catch (error: any) {
            notify(error ? error.toString() : 'An error occurred', {
                type: 'warning',
                autoHideDuration: 1500,
            });
        }
    };
    return (
        <Login>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                paddingBottom="30px"
            >
                <img src={logo} alt="logo" style={{maxWidth: 150}}/>
                { rolesLoading ? (
                    <Loading sx={{ mt: '20px' }} />
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Grid container flexDirection="column" sx={{padding: '10px'}}>
                            <Grid item container flexDirection="column">
                                <TextInput
                                    onChange={(e) => setEmail(e.target.value)}
                                    source="email"
                                    label="Email"
                                    value={emailInput}
                                    required
                                    autoComplete="on"
                                    validate={email()}
                                />
                                <PasswordInput
                                    source="password"
                                    label="Password"
                                    value={password}
                                    required
                                    autoComplete="off"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <SaveButton label="Login" icon={<LoginIcon/>}/>
                        </Grid>
                    </Form>
                )}
                
            </Box>
        </Login>
    );
};
export default LoginForm;
