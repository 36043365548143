import {CardContent, CardHeader} from "@mui/material";
import {List} from "react-admin";
import {ListProp} from '../../type/list-prop.type';

const ListWrapper = (props: ListProp) => {
    const {cardTitle, title, resource, filter, children, sort} = props;
    return (
        <>
            <CardHeader title={cardTitle}/>
            <CardContent>
                <List
                    hasCreate={false}
                    resource={resource}
                    filter={filter}
                    empty={false}
                    disableSyncWithLocation
                    title={title}
                    sort={sort}
                >
                    {children}
                </List>
            </CardContent>
        </>
    )
}
export default ListWrapper;